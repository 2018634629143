import React from "react";
import coverImage from "./assets/magic-arrow-cover.png";
import star from "./assets/star.png"; // Path to your star image

const App = () => {
  return (
    <div style={styles.container}>
      {/* Background Elements */}
      <img src={star} alt="Star" style={styles.star1} />
      <img src={star} alt="Star" style={styles.star3} />
      <img src={star} alt="Star" style={styles.star2} />
      <img src={star} alt="Star" style={styles.star4} />

      <div style={styles.content}>
        {/* Title Section */}
        <h1 style={styles.title}>The Magic Arrow</h1>

        {/* Subtitle Section */}
        <p style={styles.subtitle}>
          Follow Wan-Ke, a young hero who escapes captivity to inspire his
          people, as the Tikar embark on a perilous migration in search of a
          new home. Battling hardships and guided by mystical artifacts, their
          journey unveils timeless wisdom and breathtaking landscapes of
          ancient Africa.
        </p>

        {/* Image Section */}
        <div style={styles.imageSection}>
          <img
            src={coverImage}
            alt="The Magic Arrow Cover"
            style={styles.image}
          />
        </div>

        {/* Contact Button */}
        <a href="mailto:hello@themagicarrowbook.com" style={styles.contact}>
          Contact the Author
        </a>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: "relative", // Required for absolute positioning of background elements
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #ffe3c8, #faf3e0)", // Gradient background
    margin: 0,
    fontFamily: "'Roboto', sans-serif",
    minHeight: "100vh",
    textAlign: "center",
    paddingTop: "50px", // Added top padding
    overflow: "hidden", // Ensures no unwanted scrollbars
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "800px", // Limits width for better readability
    padding: "20px",
  },
  title: {
    fontSize: "4rem", // Large title
    fontWeight: "bold",
    color: "#8B4513", // Deep brown
    marginBottom: "20px",
    fontFamily: "'Bebas Neue', sans-serif", // Blocky font for title
    textTransform: "uppercase", // Makes title uppercase
  },
  subtitle: {
    fontSize: "1.5rem", // Larger subtitle
    color: "#61360d", // Darker brown
    marginBottom: "30px",
    lineHeight: "1.8", // Improves readability
    fontFamily: "'Roboto', sans-serif", // Clean, modern font for subtitle
  },
  imageSection: {
    marginBottom: "30px", // Adds space between image and contact button
  },
  image: {
    width: "100%",
    maxWidth: "300px", // Limits the image size
    height: "auto",
    borderRadius: "10px",
  },
  contact: {
    fontSize: "1.2rem",
    color: "#fff",
    backgroundColor: "#8B4513", // Deep brown button
    textDecoration: "none",
    fontWeight: "bold",
    padding: "12px 24px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    border: "none",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  // Background Elements
  star1: {
    position: "absolute",
    top: "10%",
    left: "20%",
    width: "50px",
    height: "50px",
    zIndex: 1,
  },
  star3: {
    position: "absolute",
    top: "30%",
    right: "15%",
    width: "100px",
    height: "50px",
    zIndex: 1,
  },
  star2: {
    position: "absolute",
    bottom: "15%",
    left: "10%",
    width: "50px",
    height: "50px",
    zIndex: 1,
  },
  star4: {
    position: "absolute",
    bottom: "10%",
    right: "25%",
    width: "80px",
    height: "40px",
    zIndex: 1,
  },
};

export default App;
